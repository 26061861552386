import React from 'react';
import Page from "../components/common/page"
import { ContentRow } from "../components/common/shared-components/layouts"
import Agreement from '../components/page-components/agb';



const CONTENT_ROWS = [
    [<Agreement/>]
];


const Agb = () => {
    return(
        <Page title="board">
            {CONTENT_ROWS.map((components, key)=>
            <ContentRow key={key} components={components} />
            )}
        </Page>)
};

export default Agb;